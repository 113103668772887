
  import { Component, Prop, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import LzStepper from "@/components/Stepper.vue";
  import { NFTWallet, NFTsTable, GiftedTokens } from "../components";
  type Step = "wallet" | "NFTs" | "giftedTokens";

  @Component({
    components: {
      LzButton,
      LzStepper,
      NFTWallet,
      NFTsTable,
      GiftedTokens
    }
  })
  export default class Read extends Vue {
    loaded = true;

    @Prop({ default: "wallet" })
    step!: Step;

    active: Step = this.step;

    handleStep(step: Step) {
      this.active = step;
    }
  }
